<template>
  <v-dialog v-model="dialog" max-width="400">
    <v-snackbar color="error" v-model="error">{{errorMessage}}</v-snackbar>
    <template v-slot:activator="{ on }">
      <v-btn icon color="error" v-on="on" small>
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </template>
    <v-card flat>
      <nice-header>
        Really delete coupon?
      </nice-header>
      <v-card-text>
        You are about to delete the coupon, {{ coupon.name }}. This action cannot be undone. Proceed?
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn rounded outlined color="error" :loading="loading" @click="deleteCoupon">Delete now</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog> 
</template>

<script>
import { deleteCoupon } from '@/graphql'

export default {
  props: ['coupon'],
  data: () => ({
    dialog: false,
    loading: false
  }),
  methods: {
    deleteCoupon () {
      this.loading = true
      this.$apollo.mutate({
        mutation: deleteCoupon,
        variables: {
          coupon: this.coupon.id
        }
      }).then((result) => {
        if (result) {
          this.dialog = false
          this.loading = false
          this.$emit('deleteCoupon')
        }
      }).catch((error) => {
        this.loading = false
        this.error = true
        this.errorMessage = error.message.replace('GraphQL error', '')
      })
    }
  }
}
</script>