<template>
  <admin-wrapper>
      <app-loader :value="$apollo.loading"/>
      <v-card flat>
          <v-container>
              <nice-header>
                  Coupons for {{ store.name }}
                  <template slot="extra">
                    <v-spacer/>
                    <new-coupon-form @create="refetch" :store="store"/>
                  </template>
              </nice-header>
              <v-alert v-if="!store.billingEnabled" type="warning" outlined>You cannot create coupons because you haven't yet enabled Stripe - go to <span class="font-weight-bold">My Billing</span> to do so</v-alert>
              <div v-else>
                <v-card-text>
                  Rentify lets you create coupons that your customers can apply at the checkout
                </v-card-text>
                <v-data-table
                  :headers="headers"
                  :items="store.coupons"
                >
                  <template v-slot:item.discount="{ item }">
                    <span v-if="item.reductionType === 'PERCENTAGE'">
                      {{item.valueOff}}% off
                    </span>
                    <span v-else>
                      {{store.currency.symbol}} {{item.valueOff }} off
                    </span>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <delete-coupon-dialog :coupon="item" @deleteCoupon="refetch"/>
                  </template>
                </v-data-table>
              </div>
          </v-container>
      </v-card>
  </admin-wrapper>
</template>

<script>
  import NewCouponForm from '@/components/NewCouponForm.vue'
  import DeleteCouponDialog from '@/components/DeleteCouponDialog.vue'
  import { stores } from '@/graphql'
  import { mapState } from 'vuex'
  export default {
    components: { NewCouponForm, DeleteCouponDialog },
    apollo: { stores },
    data: () => ({
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Discount', value: 'discount'},
        { text: 'Promo Code', value: 'promoCode'},
        { text: 'Maximum Redemptions', value: 'maxRedemptions'},
        { text: 'Redeem by', value: 'redeemBy'},
        { text: 'Actions', value: 'actions'},
      ]
    }),
    methods: {
      refetch () {
        this.$apollo.queries.stores.refetch()
      }
    },
    computed: {
      ...mapState(['selectedStore']),
      store () {
        if (this.selectedStore) {
          return this.stores.find(store => store.hashId === this.selectedStore)
        }
        return null
      }
    }

  }
</script>