<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-snackbar color="error" v-model="error">{{errorMessage}}</v-snackbar>
    <template v-slot:activator="{ on }">
      <v-btn rounded outlined color="primary" v-on="on">Create new coupon</v-btn>
    </template>
    <v-card flat>
      <nice-header>Create new coupon</nice-header>
      <v-card-text>
        <v-form v-model="valid">
          <v-text-field 
            outlined 
            rounded 
            label="Name"
            v-model="form.name"
            :rules="[rules.required]"
          ></v-text-field>
          <v-text-field 
            outlined 
            rounded 
            label="Promo Code"
            placeholder="Optional"
            hint="This will be set automatically if you do not provide a code"
            v-model="form.promoCode"
            :rules="[rules.validCode]"
          ></v-text-field>
          <v-select
            outlined
            rounded
            v-model="form.reductionType"
            label="Reduction type"
            :items="reductionTypes"
            :rules="[rules.required]"
          ></v-select>
          <v-text-field
            rounded
            outlined
            v-model="form.valueOff"
            label="Reduction amount"
            :prefix="form.reductionType === 'percentage' ? '%' : store.currency.symbol"
            :rules="[rules.required, rules.numeric, rules.morethanzero]"
          ></v-text-field>
          <v-text-field
            rounded
            outlined
            v-model="form.maxRedemptions"
            label="Maximum redemptions"
            placeholder="Optional"
            :rules="[rules.numeric]"
          ></v-text-field>
          <date-picker
            label="Redeem by"
            hint="The last date on which the coupon can be redeemed"
            v-model="form.redeemBy"
          />  
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          rounded
          outlined
          color="primary"
          :loading="loading"
          :disabled="!valid"
          @click="createCoupon"
        >Create</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createCoupon } from '@/graphql'
export default {
  props: ['store'],
  data: () => ({
    dialog: false,
    valid: null,
    loading: false,
    form: {
      name: null,
      reductionType: 'percentage',
      valueOff: 0,
      maxRedemptions: null,
      redeemBy: null,
      promoCode: null
    },
    reductionTypes: [
      { value: 'amount', text: 'Amount' },
      { value: 'percentage', text: 'Percentage' },
    ],
    rules: {
      required: v => !!v || 'This field is required',
      numeric: v => !isNaN(v) || 'Must be a valid number',
      morethanzero: v => v > 0 || 'Please enter a value greater than zero',
      validCode: (v) => {
        if (v) {
          const regex = /^[a-zA-Z0-9]+$/
          return regex.test(v) || 'Invalid promo code format - Please use numbers, letters and underscores only'
        }        
      }
    },
    error: false,
    errorMessage: null,
  }),
  methods: {
    createCoupon () {
      this.loading = true
      this.$apollo.mutate({
        mutation: createCoupon,
        variables: {
          store: this.store.hashId,
          ...this.form
        }
      }).then((result) => {
        if (result) {
          this.loading = false
          this.dialog = false
          this.$emit('create')
        }
      }).catch((error) => {
        this.error = true
        this.errorMessage = error.message.replace('GraphQL error: ', '')
        this.loading = false
      })
    }
  }
}
</script>